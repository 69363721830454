import React, {useEffect} from 'react'
import '../../../App.css';
import {Link} from 'react-scroll'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub } from '@fortawesome/free-brands-svg-icons'

const Postwork = () => {
  useEffect(() => {
    window.history.scrollRestoration = 'manual'
  }, [])

  return (
    <html lang='en'>
    <body className='page'>
        <section id='overview'>
        <nav>
          <a href="/">
            <div className="personal__logo">Zander Bournand</div>
          </a>
          <ul className="nav__link--list">
            <li className="nav__link">
              <Link to="overview" spy={true} smooth={true} offset={-50} duration={500} className="
                nav__link--anchor
                link__hover-effect
                link__hover-effect--black
              ">Overview</Link>
            </li>
            <li className="nav__link">
              <Link to="features" spy={true} smooth={true} offset={-50} duration={500} className="
                nav__link--anchor
                link__hover-effect
                link__hover-effect--black
              ">Features</Link>
            </li>
            <li className="nav__link">
              <a href="https://github.com/ZanderBournand/Postwork" target="_blank" rel="noreferrer" className="
                nav__link--anchor
                nav__link--anchor-primary2
              ">
                <FontAwesomeIcon className="fa" icon={faGithub}/>
            </a>
            </li>
          </ul>
        </nav>
        <div className="flex flex-1">
          <div className="project__info row2">
            <div className="project__info--container">
              <h1 className="project__info--title">
                <span className="text--purple">Postwork</span>&nbsp;<span className="headset">🔎</span>
              </h1>
              <div className='project__info-row'>
                <figure className="project__picture--mask">
                    <img src={require('../../../assets/Postwork_Mockup.png')} alt="swipy presentation" className="project__picture"/>
                </figure>
                <p className="project__info--para">
                Postwork is a web-application with the goal of helping students, including international, to more easily find internships, or job offerings through a discussion type forum. Postwork implements many features of today's best forum and job outlook websites, and combines them into one in order to make for the best user experience when trying to enter the professional industry.  <br/><br/> Developped using 
                    <div className='project__tech--list'>
                        <div className="project__tech--container">
                            <img src={require('../../../assets/React_L.png')} alt="react" className='tech_logo'/>
                            <strong className='text--purple2'>React.js</strong>,
                        </div>
                        <div className="project__tech--container">
                            <img src={require('../../../assets/Node_L.png')}alt="react" className='tech_logo'/>
                            <strong className='text--purple2'>Node.js / Express.js</strong>,
                        </div>
                        <div className="project__tech--container">
                            <img src={require('../../../assets/Redux_L.png')} alt="react" className='tech_logo'/>
                            <strong className='text--purple2'>Redux</strong>,
                        </div>
                        <div className="project__tech--container">
                            <img src={require('../../../assets/Mongo_L.png')} alt="react" className='tech_logo'/>
                            <strong className='text--purple2'>MongoDB</strong>,
                        </div>
                        <div className="project__tech--container">
                            <img src={require('../../../assets/MUI_L.png')} alt="react" className='tech_logo'/>
                            <strong className='text--purple2'>Material UI</strong>,
                        </div>
                    </div>
                </p>
              </div>
            </div>
          </div>
        </div>
        </section>
        <section id="features">
            <div className='project_features--container'>
              
                <div className='row'>
                    <h1 className="section__title">Here are some of <span className="text--purple">Postwork's</span> features</h1>
                </div>

                <div className='project_features--one2'>
                  <div className='projectImageContainer'>
                    <img src={require('../../../assets/Postwork_Login.png')} alt="postwork login" className='projectImage'/>
                  </div>
                  <div className='project-feature--details'>
                    <h1 className='project_feature--title'>Create your accout or Login</h1>
                      <h3 className='project-feature--single'>• Login with secured <span className='text--purple'>email/password  </span> system through Postwork</h3>
                      <h3 className='project-feature--single'>• <span className='text--purple'>Google OAuth 2.0 </span> is also available for ease of use</h3>
                      <h3 className='project-feature--single'>• Setup basic profile info once account is created</h3>
                  </div>
                </div>

                <div className='featureDivider'/>

                <div className='project_features--one2'>
                  <div className='projectImageContainer'>
                    <img src={require('../../../assets/Postwork_Home.png')} alt="postwork home" className='projectImage'/>
                  </div>
                  <div className='project-feature--details'>
                    <h1 className='project_feature--title'>Browse the post feed</h1>
                      <h3 className='project-feature--single'>• <span className='text--purple'>Post </span> your discovered oppurtonity to <span className='text--purple'>share </span> it with others</h3>
                      <h3 className='project-feature--single'>• <span className='text--purple'>Upvote/Downvote  </span>, <span className='text--purple'>bookmark </span> and much more to better make use of Postwork</h3>
                      <h3 className='project-feature--single'>• Sort by <span className='text--purple'>"Hot" </span> or <span className='text--purple'>"New" </span> to see the latest and greatest</h3>
                      <h3 className='project-feature--single'>• <span className='text--purple'>Search </span> through all posts to filter information</h3>
                  </div>
                </div>

                <div className='featureDivider'/>

                <div className='project_features--one2'>
                  <div className='projectImageContainer'>
                    <img src={require('../../../assets/Postwork_Comments.png')} alt="postwork comments" className='projectImage'/>
                  </div>
                  <div className='project-feature--details'>
                    <h1 className='project_feature--title'>Discuss with others</h1>
                      <h3 className='project-feature--single'>• Get in contact by <span className='text--purple'>commenting  </span> under posts</h3>
                      <h3 className='project-feature--single'>• <span className='text--purple'>Reply  </span> to any posts to engage in a conversation</h3>
                      <h3 className='project-feature--single'>• <span className='text--purple'>Edit  </span>, and <span className='text--purple'>delete  </span> your posts in the 5min after posting</h3>
                  </div>
                </div>

                <div className='featureDivider'/>

                <div className='project_features--one2'>
                  <div className='projectImageContainer'>
                    <img src={require('../../../assets/Postwork_Profile.png')} alt="postwork profile"className='projectImage'/>
                  </div>
                  <div className='project-feature--details'>
                    <h1 className='project_feature--title'>Build your profile</h1>
                      <h3 className='project-feature--single'>• <span className='text--purple'>Browse  </span> users' profiles, and see their latest news</h3>
                      <h3 className='project-feature--single'>• Check your <span className='text--purple'>stats  </span>, and <span className='text--purple'>edit  </span> your about section to reflect your current status</h3>
                      <h3 className='project-feature--single'>• Check out their <span className='text--purple'>personal feed  </span> for any interesting oppurtonities</h3>
                  </div>
                </div>

                <div className='featureDivider'/>

                <div className='project_features--one2'>
                  <div className='projectImageContainer'>
                    <img src={require('../../../assets/Postwork_Bookmarks.png')} alt="postwork bookmarks" className='projectImage'/>
                  </div>
                  <div className='project-feature--details'>
                    <h1 className='project_feature--title'>Bookmark posts</h1>
                      <h3 className='project-feature--single'>• <span className='text--purple'>Save  </span> any posts for later by simpling <span className='text--purple'>bookmarking  </span> it within Postwork</h3>
                      <h3 className='project-feature--single'>• <span className='text--purple'>Manage  </span> your bookmarks within the "bookmarks" tab</h3>
                  </div>
                </div>

            </div>
        </section>
        <footer className='footer2'>
          <div className="row footer__row">
            <div className="footer__social--list">
              <a href="https://github.com/ZanderBournand" target="_blank" rel="noreferrer" className="
                footer__social--link
                link__hover-effect
                link__hover-effect--white
              ">GitHub</a>
              <a href="https://www.linkedin.com/in/zander-bournand/" target="_blank" rel="noreferrer" className="
                footer__social--link
                link__hover-effect
                link__hover-effect--white
              ">LinkedIn</a>
              <a href="mailto:z.bournand@gmail.com" target="_blank" rel="noreferrer" className="
                footer__social--link
                link__hover-effect
                link__hover-effect--white
              " >Email</a>
              <a href={'/resume'} target="_blank" rel="noreferrer" className="
                footer__social--link
                link__hover-effect
                link__hover-effect--white
              ">Resume</a>
            </div>
            <div className="footer__copyright">Copyright © 2022 Zander Bournand</div>
          </div>
        </footer>
    </body>
    </html>
  )
}

export default Postwork