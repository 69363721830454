import React, {useEffect} from 'react'
import '../../../App.css';
import {Link} from 'react-scroll'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGitlab } from '@fortawesome/free-brands-svg-icons'

const Clubfinity = () => {
  useEffect(() => {
    window.history.scrollRestoration = 'manual'
  }, [])

  return (
    <html lang='en'>
    <body className='page'>
        <section id='overview'>
        <nav>
          <a href="/">
            <div className="personal__logo">Zander Bournand</div>
          </a>
          <ul className="nav__link--list">
            <li className="nav__link">
              <Link to="overview" spy={true} smooth={true} offset={-50} duration={500} className="
                nav__link--anchor
                link__hover-effect
                link__hover-effect--black
              ">Overview</Link>
            </li>
            <li className="nav__link">
              <Link to="features" spy={true} smooth={true} offset={-50} duration={500} className="
                nav__link--anchor
                link__hover-effect
                link__hover-effect--black
              ">Features</Link>
            </li>
            <li className="nav__link">
              <a href="https://gitlab.com/ufsec/clubfinity" target="_blank" rel="noreferrer" className="
                nav__link--anchor
                nav__link--anchor-primary2
              ">
                <FontAwesomeIcon className="fa" icon={faGitlab}/>
            </a>
            </li>
          </ul>
        </nav>
        <div className="flex flex-1">
          <div className="project__info row2">
            <div className="project__info--container">
              <h1 className="project__info--title">
                <span className="text--purple">Clubfinity</span>&nbsp;<span className="headset">📚</span>
              </h1>
              <div className='project__info-row'>
                <figure className="project__picture--mask">
                    <img src={require('../../../assets/Clubfinity_Mockup.png')} alt="swipy presentation" className="project__picture"/>
                </figure>
                <p className="project__info--para">
                Product of the <a href="https://ufsec.org" className='text--purple'>Software Engineering Club</a> @ UF <br/><br/>
                Clubfinity is a mobile application which allows students on campus to have an easier access to club information, as well for higher exposure rates for organizations within the University of Florida. Clubfinity aims to fill the current vaccumm between students, and clubs, accelerating the next wave of organizations, and their members on campus!   <br/><br/> Developped using 
                    <div className='project__tech--list'>
                        <div className="project__tech--container">
                            <img src={require('../../../assets/React_L.png')} alt="react" className='tech_logo'/>
                            <strong className='text--purple2'>React Native</strong>,
                        </div>
                        <div className="project__tech--container">
                            <img src={require('../../../assets/Node_L.png')}alt="react" className='tech_logo'/>
                            <strong className='text--purple2'>Node.js / Express.js</strong>,
                        </div>
                        <div className="project__tech--container">
                            <img src={require('../../../assets/Mongo_L.png')} alt="react" className='tech_logo'/>
                            <strong className='text--purple2'>MongoDB</strong>,
                        </div>
                        <div className="project__tech--container">
                            <img src={require('../../../assets/Docker_L.png')} alt="react" className='tech_logo'/>
                            <strong className='text--purple2'>Docker</strong>
                        </div>
                    </div>
                </p>
              </div>
            </div>
          </div>
        </div>
        </section>
        <section id="features">
            <div className='project_features--container'>
              
                <div className='row'>
                    <h1 className="section__title">Some of <span className="text--purple">Clubfinity's</span> features I worked on</h1>
                </div>

                <div className='project_features--one2'>
                  <div className='project_features--videoContainerDuo'>
                    <img src={require('../../../assets/ipro.png')}alt="iphone frame" className='iphoneFrameLeft'/>
                    <img src={require('../../../assets/Clubfinity_Login.png')}alt="iphone frame" className='project_features--videoLeft'/>
                    <img src={require('../../../assets/ipro.png')}alt="iphone frame" className='iphoneFrameRight'/>
                    <img src={require('../../../assets/Clubfinity_Profile.png')}alt="iphone frame" className='project_features--videoRight'/>
                  </div>
                  <div className='project-feature--details'>
                    <h1 className='project_feature--title'>Improved Login & Profile Experience</h1>
                      <h3 className='project-feature--single'>• Dynamic <span className='text--purple'>startup animation</span> w/ in-house <span className='text--purple'>authentication</span> system</h3>
                      <h3 className='project-feature--single'>• Profile allowing users to view their managed, and followed clubs</h3>
                      <h3 className='project-feature--single'>• Acts as a hub for a wide variety of <span className='text--purple'>management tasks</span> (ex: settings, club configurations, etc...)</h3>
                  </div>
                </div>

                <div className='featureDivider'/>

                <div className='project_features--one2'>
                    <div className='project_features--videoContainerDuo'>
                        <img src={require('../../../assets/ipro.png')}alt="iphone frame" className='iphoneFrameLeft'/>
                        <img src={require('../../../assets/Clubfinity_EditProfile.png')}alt="iphone frame" className='project_features--videoLeft'/>
                        <img src={require('../../../assets/ipro.png')}alt="iphone frame" className='iphoneFrameRight'/>
                        <img src={require('../../../assets/Clubfinity_SubmitClub.png')}alt="iphone frame" className='project_features--videoRight'/>
                    </div>
                  <div className='project-feature--details'>
                    <h1 className='project_feature--title'>User & Club image hosting</h1>
                      <h3 className='project-feature--single'>• Provides direct <span className='text--purple'>cloud upload</span> of users' profile or club images</h3>
                      <h3 className='project-feature--single'>• Image selection upon user/club creation & can be <span className='text--purple'>edited at anytime</span> via settings</h3>
                      <h3 className='project-feature--single'>• Plans include image uploading for more features, providing a <span className='text--purple'>hosting solution</span> for clubs</h3>
                  </div>
                </div>

                <div className='featureDivider'/>

                <div className='project_features--one2'>
                  <div className='projectImageContainer'>
                    <img src={require('../../../assets/Clubfinity_DiscordBugTickets.png')} alt="postwork comments" className='projectImage'/>
                  </div>
                  <div className='project-feature--details'>
                    <h1 className='project_feature--title'>Bug Report Ticket System</h1>
                      <h3 className='project-feature--single'>• In-house <span className='text--purple'>bug report system</span>, populated by users via the app</h3>
                      <h3 className='project-feature--single'>• Integrated with Clubfinity's discord bots to provide <span className='text--purple'>facilitated admin tasks</span></h3>
                      <h3 className='project-feature--single'>• Allows for <span className='text--purple'>direct feedback</span> to users upon resolving ticket</h3>
                  </div>
                </div>

                <div className='featureDivider'/>

                <div className='project_features--one2'>
                  <div className='projectImageContainer'>
                    <img src={require('../../../assets/Clubfinity_DiscordClubTickets.png')} alt="postwork profile"className='projectImage'/>
                  </div>
                  <div className='project-feature--details'>
                    <h1 className='project_feature--title'>Club Creation Ticket System</h1>
                      <h3 className='project-feature--single'>• Tickets immediately dispatched to Clubfinity's discord bots</h3>
                      <h3 className='project-feature--single'>• Provides <span className='text--purple'>automatic club creation</span>, or denial via discord tickets for Clubfinity's admins</h3>
                      <h3 className='project-feature--single'>• Allows for users to receive <span className='text--purple'>admin feedback</span> upon creation, or denial of ticket</h3>
                  </div>
                </div>

                <div className='featureDivider'/>

                <div className='project_features--one2'>
                  <div className='projectImageContainer'>
                    <img src={require('../../../assets/Clubfinity_DiscordAnnouncements.png')} alt="postwork bookmarks" className='projectImage'/>
                  </div>
                  <div className='project-feature--details'>
                    <h1 className='project_feature--title'>App & Discord Info Sync</h1>
                      <h3 className='project-feature--single'>• Clubfinity app & discord bot automatically <span className='text--purple'>sync data</span> upon club activity created</h3>
                      <h3 className='project-feature--single'>• Eliminates the need for redundant announcements, making for a <span className='text--purple'>seamless migration</span> for clubs towards Clubfnity</h3>
                      <h3 className='project-feature--single'>• Plans to include similar integration via more platforms such as <span className='text--purple'>Slack</span>, <span className='text--purple'>MS Teams</span>, etc...</h3>
                  </div>
                </div>

            </div>
        </section>
        <footer className='footer2'>
          <div className="row footer__row">
            <div className="footer__social--list">
              <a href="https://github.com/ZanderBournand" target="_blank" rel="noreferrer" className="
                footer__social--link
                link__hover-effect
                link__hover-effect--white
              ">GitHub</a>
              <a href="https://www.linkedin.com/in/zander-bournand/" target="_blank" rel="noreferrer" className="
                footer__social--link
                link__hover-effect
                link__hover-effect--white
              ">LinkedIn</a>
              <a href="mailto:z.bournand@gmail.com" target="_blank" rel="noreferrer" className="
                footer__social--link
                link__hover-effect
                link__hover-effect--white
              " >Email</a>
              <a href={'/resume'} target="_blank" rel="noreferrer" className="
                footer__social--link
                link__hover-effect
                link__hover-effect--white
              ">Resume</a>
            </div>
            <div className="footer__copyright">Copyright © 2022 Zander Bournand</div>
          </div>
        </footer>
    </body>
    </html>
  )
}

export default Clubfinity