import React, {useEffect} from 'react'
import '../../../App.css';
import {Link} from 'react-scroll'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub } from '@fortawesome/free-brands-svg-icons'

const ListenNext = () => {
  useEffect(() => {
    window.history.scrollRestoration = 'manual'
  }, [])

  return (
    <html lang='en'>
    <body className='page'>
        <section id='overview'>
        <nav>
          <a href="/">
            <div className="personal__logo">Zander Bournand</div>
          </a>
          <ul className="nav__link--list">
            <li className="nav__link">
              <Link to="overview" spy={true} smooth={true} offset={-50} duration={500} className="
                nav__link--anchor
                link__hover-effect
                link__hover-effect--black
              ">Overview</Link>
            </li>
            <li className="nav__link">
              <Link to="features" spy={true} smooth={true} offset={-50} duration={500} className="
                nav__link--anchor
                link__hover-effect
                link__hover-effect--black
              ">Features</Link>
            </li>
            <li className="nav__link">
              <a href="https://github.com/ZanderBournand/ListenNext" target="_blank" rel="noreferrer" className="
                nav__link--anchor
                nav__link--anchor-primary2
              ">
                <FontAwesomeIcon className="fa" icon={faGithub}/>
            </a>
            </li>
          </ul>
        </nav>
        <div className="flex flex-1">
          <div className="project__info row2">
            <div className="project__info--container">
              <h1 className="project__info--title">
                <span className="text--purple">ListenNext</span>&nbsp;<span className="headset">🔮</span>
              </h1>
              <div className='project__info-row'>
                <figure className="project__picture--mask">
                    <img src={require('../../../assets/ListenNext_Mockup.png')} alt="swipy presentation" className="project__picture"/>
                </figure>
                <p className="project__info--para">
                ListenNext is web-application allowing users to browse upcoming music releases, having a first look on what to listen to next. ListenNext provides personal music/artist recommendations powered by today's top streaming platforms such as Spotify, Apple Music, and more. The platform aims to add new features, such as browsing release snippets, and keeping up to date with the industry's latest news!  <br/><br/> Developped using 
                    <div className='project__tech--list'>
                        <div className="project__tech--container">
                            <img src={require('../../../assets/Go_L.png')} alt="react" className='tech_logo'/>
                            <strong className='text--purple2'>Golang</strong>,
                        </div>
                        <div className="project__tech--container">
                            <img src={require('../../../assets/GraphQL_L.png')} alt="react" className='tech_logo'/>
                            <strong className='text--purple2'>GraphQL</strong>,
                        </div>
                        <div className="project__tech--container">
                            <img src={require('../../../assets/PostgreSQL_L.png')}alt="react" className='tech_logo'/>
                            <strong className='text--purple2'>PostgreSQL</strong>,
                        </div>
                        <div className="project__tech--container">
                            <img src={require('../../../assets/NextJS_L3.png')} alt="react" className='tech_logo'/>
                            <strong className='text--purple2'>Next.js</strong>,
                        </div>
                        <div className="project__tech--container">
                            <img src={require('../../../assets/Typescript_L.png')} alt="react" className='tech_logo'/>
                            <strong className='text--purple2'>Typescript</strong>,
                        </div>
                        <div className="project__tech--container">
                            <img src={require('../../../assets/Tailwind_L.png')} alt="react" className='tech_logo'/>
                            <strong className='text--purple2'>Tailwind CSS</strong>,
                        </div>
                    </div>
                </p>
              </div>
            </div>
          </div>
        </div>
        </section>
        <section id="features">
            <div className='project_features--container'>
              
                <div className='row'>
                    <h1 className="section__title">Here are some of <span className="text--purple">ListenNext's</span> features</h1>
                </div>

                <div className='project_features--one2'>
                  <div className='projectImageContainer'>
                    <img src={require('../../../assets/ListenNext_Home.png')} alt="postwork login" className='projectImage'/>
                  </div>
                  <div className='project-feature--details'>
                    <h1 className='project_feature--title'>Browse upcoming releases</h1>
                      <h3 className='project-feature--single'>• See <span className='text--purple'>new</span> & <span className='text--purple'>future</span> music currently on track for release</h3>
                      <h3 className='project-feature--single'>• Differentiate albums, and singles, through the <span className='text--purple'>filter system</span></h3>
                      <h3 className='project-feature--single'>• Keep up with the <span className='text--purple'>latest features</span> of ListenNext, such as the new <span className='text--purple'>recommendations!</span></h3>
                  </div>
                </div>

                <div className='featureDivider'/>

                <div className='project_features--one2'>
                  <div className='projectImageContainer'>
                    <img src={require('../../../assets/ListenNext_Search.png')} alt="postwork home" className='projectImage'/>
                  </div>
                  <div className='project-feature--details'>
                    <h1 className='project_feature--title'>Search your favorite artists</h1>
                      <h3 className='project-feature--single'>• Browse <span className='text--purple'>any artist</span>, and look out for any upcoming, or new music they released</h3>
                      <h3 className='project-feature--single'>• At the same time, search any <span className='text--purple'>relevant artists</span>, as you might find your next favorite artists there</h3>
                  </div>
                </div>

                <div className='featureDivider'/>

                <div className='project_features--one2'>
                  <div className='projectImageContainer'>
                    <img src={require('../../../assets/ListenNext_Login.png')} alt="postwork comments" className='projectImage'/>
                  </div>
                  <div className='project-feature--details'>
                    <h1 className='project_feature--title'>Create your account or login</h1>
                      <h3 className='project-feature--single'>• <span className='text--purple'>Sign in</span> or <span className='text--purple'>Sign up</span>, using our own login system</h3>
                      <h3 className='project-feature--single'>• Use your preferred <span className='text--purple'>streaming platform</span> to access <span className='text--purple'>recommendations</span> on upcoming releases</h3>
                      <h3 className='project-feature--single'>• <span className='text--purple'>Spotify</span> currently supported, with <span className='text--purple'>Apple Music</span> to come very soon</h3>
                  </div>
                </div>

                <div className='featureDivider'/>

                <div className='project_features--one2'>
                  <div className='projectImageContainer'>
                    <img src={require('../../../assets/ListenNext_Recommendations.png')} alt="postwork profile"className='projectImage'/>
                  </div>
                  <div className='project-feature--details'>
                    <h1 className='project_feature--title'>Personal recommendations</h1>
                      <h3 className='project-feature--single'>• Check out your own <span className='text--purple'>personal recommendations</span> for new and upcoming releases</h3>
                      <h3 className='project-feature--single'>• <span className='text--purple'>Filter</span> out the music titles by type, such as albums and singles</h3>
                      <h3 className='project-feature--single'>• Easily browse any <span className='text--purple'>relevant artists</span> from your recommendations for further info</h3>
                  </div>
                </div>

            </div>
        </section>
        <footer className='footer2'>
          <div className="row footer__row">
            <div className="footer__social--list">
              <a href="https://github.com/ZanderBournand" target="_blank" rel="noreferrer" className="
                footer__social--link
                link__hover-effect
                link__hover-effect--white
              ">GitHub</a>
              <a href="https://www.linkedin.com/in/zander-bournand/" target="_blank" rel="noreferrer" className="
                footer__social--link
                link__hover-effect
                link__hover-effect--white
              ">LinkedIn</a>
              <a href="mailto:z.bournand@gmail.com" target="_blank" rel="noreferrer" className="
                footer__social--link
                link__hover-effect
                link__hover-effect--white
              " >Email</a>
              <a href={'/resume'} target="_blank" rel="noreferrer" className="
                footer__social--link
                link__hover-effect
                link__hover-effect--white
              ">Resume</a>
            </div>
            <div className="footer__copyright">Copyright © 2022 Zander Bournand</div>
          </div>
        </footer>
    </body>
    </html>
  )
}

export default ListenNext