import React, {useRef} from 'react'
import { InView } from 'react-intersection-observer';
import '../../../../App.css'

const SwipyVideo = ({videoFile, poster}) => {

  document.addEventListener("DOMContentLoaded", function() {
    var lazyVideos = [].slice.call(document.querySelectorAll("video.lazy"));
  
    if ("IntersectionObserver" in window) {
      var lazyVideoObserver = new IntersectionObserver(function(entries, observer) {
        entries.forEach(function(video) {
          if (video.isIntersecting) {
            for (var source in video.target.children) {
              var videoSource = video.target.children[source];
              if (typeof videoSource.tagName === "string" && videoSource.tagName === "SOURCE") {
                videoSource.src = videoSource.dataset.src;
              }
            }
  
            video.target.load();
            video.target.classList.remove("lazy");
            lazyVideoObserver.unobserve(video.target);
          }
        });
      });
  
      lazyVideos.forEach(function(lazyVideo) {
        lazyVideoObserver.observe(lazyVideo);
      });
    }
  });

  const videoRef = useRef(null)

  const handleVideo = (inView) => {
    if (inView) {
        videoRef.current.play();
    }
    else {
        if (videoRef.current.play) {
            videoRef.current.pause();
        }
    }
  }

  return (
    <InView threshold={0.5} onChange={(inView) => handleVideo(inView)} className='project_features--videoContainer'>
        <img src={require('../../../../assets/ipro.png')}alt="iphone frame" className='iphoneFrame'/>
        <video src={videoFile} ref={videoRef} playsinline autoPlay loop muted className='project_features--video' poster={poster}/>
    </InView>
  )
}

export default SwipyVideo