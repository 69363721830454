import '../../App.css';
import illustration from '../../assets/undraw_programming.svg'
import profile from '../../assets/profile.jpg'
import React, {useEffect} from 'react'
import {Link} from 'react-scroll'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faFilePdf, faAngleRight} from '@fortawesome/free-solid-svg-icons'
import { faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons'
import {useNavigate} from 'react-router-dom'

const Home = () => {

  const navigate = useNavigate()

  useEffect(() => {
    window.history.scrollRestoration = 'manual'
  }, [])

  return (
    <html lang="en">

    <body className='page'>
      <section id="about-me">
        <nav>
          <div className="personal__logo">Zander Bournand</div>
          <ul className="nav__link--list">
            <li className="nav__link">
              <Link to="languages" spy={true} smooth={true} offset={-50} duration={500} className="
                nav__link--anchor
                link__hover-effect
                link__hover-effect--black
              ">Skills</Link>
            </li>
            <li className="nav__link">
              <Link to="projects" spy={true} smooth={true} offset={-50} duration={500} className="
                nav__link--anchor
                link__hover-effect
                link__hover-effect--black
              ">Projects</Link>
            </li>
            <li className="nav__link">
              <a href="mailto:z.bournand@gmail.com" className="
                nav__link--anchor
                nav__link--anchor-primary
              ">Contact</a>
            </li>
          </ul>
        </nav>
        <div className="flex flex-1">
          <div className="about-me__info row">
            <div className="about-me__info--container">
              <figure className="about-me__picture--mask">
                <img src={profile} className="about-me__picture" alt="me"/>
              </figure>
              <h1 className="about-me__info--title">
                Hey! I'm <span className="text--purple">Zander Bournand</span>&nbsp;<span className="wave">👋</span>
              </h1>
              <p className="about-me__info--para">
                I'm a <strong className="text--purple">full-stack software engineer</strong> with a strong passion for building
                efficient and scalable web / mobile
                applications with great user experiences that positively impact the world.
              </p>
              <div className="about-me__links">
                <a href="https://www.linkedin.com/in/zander-bournand/" target="_blank" rel="noreferrer" className="about-me__link">
                  <FontAwesomeIcon className="fa" icon={faLinkedin}/>
                </a>
                <a href="https://github.com/ZanderBournand" target="_blank" rel="noreferrer" className="about-me__link">
                  <FontAwesomeIcon className="fa" icon={faGithub}/>
                </a>
                <a href="mailto:z.bournand@gmail.com" target="_blank" rel="noreferrer" className="about-me__link">
                  <FontAwesomeIcon className="fa" icon={faEnvelope}/>
                </a>
                <a href={'/resume'} target="_blank" rel="noreferrer" className="about-me__link">
                  <FontAwesomeIcon className="fa" icon={faFilePdf}/>
                </a>
              </div>
            </div>
            <figure className="about-me__img--container">
              <img src={illustration} className="about-me__img" alt=""/>
            </figure>
          </div>
        </div>
      </section>
      <section id="languages">
        <div className="container">
          <div className="row">
            <h1 className="section__title">This is my <span className="text--purple">technology stack</span></h1>
            <div className="language__list">
              <div className="language">
                <figure className="language__img--wrapper">
                  <img src="https://cdn.iconscout.com/icon/free/png-256/java-59-1174952.png"
                    alt="TypeScript Logo" className="language__img" />
                </figure>
                <span className="language__name">Java</span>
              </div>
              <div className="language">
                <figure className="language__img--wrapper">
                  <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/05/Go_Logo_Blue.svg/1280px-Go_Logo_Blue.svg.png" alt="CSS Logo"
                    className="language__img2" />
                </figure>
                <span className="language__name">Go</span>
              </div>
              <div className="language">
                <figure className="language__img--wrapper">
                  <img src="https://cdn.iconscout.com/icon/free/png-256/python-2-226051.png" alt="Nuxt Logo"
                    className="language__img" />
                </figure>
                <span className="language__name">Python</span>
              </div>
              <div className="language">
                <figure className="language__img--wrapper">
                  <img src="https://cdn.iconscout.com/icon/free/png-256/free-postgresql-8-1175119.png" alt="React Logo"
                    className="language__img" />
                </figure>
                <span className="language__name">PostgreSQL</span>
              </div>
              <div className="language">
                <figure className="language__img--wrapper">
                  <img src="https://cdn.iconscout.com/icon/free/png-256/free-typescript-1-1175078.png" alt="JavaScript Logo"
                    className="language__img" />
                </figure>
                <span className="language__name">Typescript</span>
              </div>
              <div className="language">
                <figure className="language__img--wrapper">
                  <img src="https://cdn.iconscout.com/icon/free/png-256/react-3-1175109.png" alt="React Logo"
                    className="language__img" />
                </figure>
                <span className="language__name">React & React Native</span>
              </div>
              {/* <div className="language">
                <figure className="language__img--wrapper">
                  <img src="https://cdn.iconscout.com/icon/free/png-256/c-4-226082.png" alt="Vue Logo"
                    className="language__img" />
                </figure>
                <span className="language__name">C++</span>
              </div> */}
              <div className="language">
                <figure className="language__img--wrapper">
                  <img src={require('../../assets/node.png')} alt="MobX Logo" className="language__img2" />
                </figure>
                <span className="language__name">NodeJS</span>
              </div>
              <div className="language">
                <figure className="language__img--wrapper">
                  <img src="https://cdn.iconscout.com/icon/free/png-256/mongodb-3521676-2945120.png" alt="React Logo"
                    className="language__img" />
                </figure>
                <span className="language__name">MongoDB</span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="projects">
        <div className="containerProjects">
          <div className="row">
            <h1 className="section__title">Here are some of my <span className="text--purple">projects</span></h1>
            <ul className="project__list">
            <li className="project">
                <div className="project__wrapper">
                  <img src={require('../../assets/Clubfinity_Mockup.png')} className="project__img" alt="Car subscription project"/>
                  <div className="project__description">
                    <div className="project__description--title-container">
                      <h3 className="project__description--title">Clubfinity</h3>
                      <div className='sec-notice'>
                        <span className="sec-notice-text">Software Engineeing Club @ UF</span>
                      </div>
                    </div>
                    <h4 className="project__description--sub-title">
                      React Native, NodeJS, ExpressJS, MongoDB, Docker
                    </h4>
                    <p className="project__description--para">
                      Clubfinity is a mobile application which allows students on campus to have an easier access to club information, as well for higher exposure rates for organizations within the University of Florida. Clubfinity aims to fill the current vaccumm between students, and clubs, accelerating the next wave of organizations, and their members on campus! 
                    </p>
                    <div className="project__description--links">
                      <a href="https://gitlab.com/ufsec/clubfinity" target="_blank"  rel="noreferrer" className="project__description--link">
                        <FontAwesomeIcon icon={faGithub}/>
                      </a>
                      {/* <a href="https://www.yourwebsite.com" className="project__description--link">
                        <FontAwesomeIcon icon={faLink} />
                      </a> */}
                      <a href="/clubfinity" className="project__description--link">
                        <div className="project__description--linkText" onClick={navigate(('/clubfinity'))}>View More</div>
                        <FontAwesomeIcon icon={faAngleRight} />
                      </a>
                    </div>
                  </div>
                </div>
              </li>
              <li className="project">
                <div className="project__wrapper">
                  <img src={require('../../assets/ListenNext_Mockup.png')} className="project__img" alt="Car subscription project"/>
                  <div className="project__description">
                    <h3 className="project__description--title">ListenNext</h3>
                    <h4 className="project__description--sub-title">
                      Golang, PostgreSQL, GraphQL, Next.JS, Typescript
                    </h4>
                    <p className="project__description--para">
                    ListenNext is web-application allowing users to browse upcoming music releases, having a first look on what to listen to next. ListenNext provides personal music/artist recommendations powered by today's top streaming platforms such as Spotify, Apple Music, and more. The platform aims to add new features, such as browsing release snippets, and keeping up to date with the industry's latest news!
                    </p>
                    <div className="project__description--links">
                      <a href="https://github.com/ZanderBournand/ListenNext" target="_blank"  rel="noreferrer" className="project__description--link">
                        <FontAwesomeIcon icon={faGithub}/>
                      </a>
                      {/* <a href="https://www.yourwebsite.com" className="project__description--link">
                        <FontAwesomeIcon icon={faLink} />
                      </a> */}
                      <a href="/listennext" className="project__description--link">
                        <div className="project__description--linkText" onClick={navigate(('/listennext'))}>View More</div>
                        <FontAwesomeIcon icon={faAngleRight} />
                      </a>
                    </div>
                  </div>
                </div>
              </li>
              <li className="project">
                <div className="project__wrapper">
                  <img src={require('../../assets/Swipy Screens.png')} className="project__img" alt="Car subscription project"/>
                  <div className="project__description">
                    <h3 className="project__description--title">Swipy</h3>
                    <h4 className="project__description--sub-title">
                      React Native, Tailwind, Redux, Firebase
                    </h4>
                    <p className="project__description--para">
                      Swipy is a cross-platform mobile application which aims to help artists and producers connect together through a discvoery feed system. Users can upload their work to the application's database, and start sharing their work to the world. Swipy allows for an easy connection system through matching invitations which users can send out if interested with the artist/producer's work.
                    </p>
                    <div className="project__description--links">
                      <a href="https://github.com/ZanderBournand/Swipy" target="_blank"  rel="noreferrer" className="project__description--link">
                        <FontAwesomeIcon icon={faGithub}/>
                      </a>
                      {/* <a href="https://www.yourwebsite.com" className="project__description--link">
                        <FontAwesomeIcon icon={faLink} />
                      </a> */}
                      <a href="/swipy" className="project__description--link">
                        <div className="project__description--linkText" onClick={navigate(('/swipy'))}>View More</div>
                        <FontAwesomeIcon icon={faAngleRight} />
                      </a>
                    </div>
                  </div>
                </div>
              </li>
              <li className="project">
                <div className="project__wrapper">
                  <img src={require('../../assets/Postwork_Mockup.png')} className="project__img" alt="Car subscription project"/>
                  <div className="project__description">
                    <h3 className="project__description--title">Postwork</h3>
                    <h4 className="project__description--sub-title">
                      React, NodeJS, ExpressJS, MongoDB, Redux, Material UI
                    </h4>
                    <p className="project__description--para">
                      Postwork is a web-application with the goal of helping students, including international, to more easily find internships, or job offerings through a discussion type forum. Postwork implements many features of today's best forum and job outlook websites, and combines them into one in order to make for the best user experience when trying to enter the professional industry. 
                    </p>
                    <div className="project__description--links">
                      <a href="https://github.com/ZanderBournand/Postwork" target="_blank"  rel="noreferrer" className="project__description--link">
                        <FontAwesomeIcon icon={faGithub}/>
                      </a>
                      {/* <a href="https://www.yourwebsite.com" className="project__description--link">
                        <FontAwesomeIcon icon={faLink} />
                      </a> */}
                      <a href="/postwork" className="project__description--link">
                        <div className="project__description--linkText">View More</div>
                        <FontAwesomeIcon icon={faAngleRight} />
                      </a>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <footer>
        <div className="row footer__row">
          <div className="footer__social--list">
            <a href="https://github.com/ZanderBournand" target="_blank" rel="noreferrer" className="
              footer__social--link
              link__hover-effect
              link__hover-effect--white
            ">GitHub</a>
            <a href="https://www.linkedin.com/in/zander-bournand/" target="_blank" rel="noreferrer" className="
              footer__social--link
              link__hover-effect
              link__hover-effect--white
            ">LinkedIn</a>
            <a href="mailto:z.bournand@gmail.com" target="_blank" rel="noreferrer" className="
              footer__social--link
              link__hover-effect
              link__hover-effect--white
            " >Email</a>
            <a href={'/resume'} target="_blank" rel="noreferrer" className="
              footer__social--link
              link__hover-effect
              link__hover-effect--white
            ">Resume</a>
          </div>
          <div className="footer__copyright">Copyright © 2022 Zander Bournand</div>
        </div>
      </footer>
    </body>

    </html>
  )
}

export default Home