import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import Home from './components/Home/Home';
import Postwork from './components/Projects/Postwork/Postwork';
import Swipy from './components/Projects/Swipy/Swipy';
import ListenNext from './components/Projects/ListenNext/ListenNext';
import Clubfinity from './components/Projects/Clubfinity/Clubfinity';
import Resume from './components/Resume/Resume';


export const App = () => {

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/swipy" element={<Swipy/>}/>
        <Route path="/postwork" element={<Postwork/>}/>
        <Route path="/listennext" element={<ListenNext/>}/>
        <Route path="/clubfinity" element={<Clubfinity/>}/>
        <Route path="/resume" element={<Resume/>}/>
      </Routes>
    </Router>
  );
}

export default App;
