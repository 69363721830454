import React, {useEffect} from 'react'
import '../../../App.css';
import {Link} from 'react-scroll'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub } from '@fortawesome/free-brands-svg-icons'
import SwipyVideo from './Video/SwipyVideo';

const Swipy = () => {

  useEffect(() => {
    window.history.scrollRestoration = 'manual'
  }, [])

  return (
    <html lang='en'>
    <body className='page'>
        <section id='overview'>
        <nav>
          <a href="/">
            <div className="personal__logo">Zander Bournand</div>
          </a>
          <ul className="nav__link--list">
            <li className="nav__link">
              <Link to="overview" spy={true} smooth={true} offset={-50} duration={500} className="
                nav__link--anchor
                link__hover-effect
                link__hover-effect--black
              ">Overview</Link>
            </li>
            <li className="nav__link">
              <Link to="features" spy={true} smooth={true} offset={-50} duration={500} className="
                nav__link--anchor
                link__hover-effect
                link__hover-effect--black
              ">Features</Link>
            </li>
            <li className="nav__link">
              <a href="https://github.com/ZanderBournand/Swipy" target="_blank" rel="noreferrer" className="
                nav__link--anchor
                nav__link--anchor-primary2
              ">
                <FontAwesomeIcon className="fa" icon={faGithub}/>
            </a>
            </li>
          </ul>
        </nav>
        <div className="flex flex-1">
          <div className="project__info row2">
            <div className="project__info--container">
              <h1 className="project__info--title">
                <span className="text--purple">Swipy</span>&nbsp;<span className="headset">🎧</span>
              </h1>
              <div className='project__info-row'>
                <figure className="project__picture--mask">
                    <img src={require('../../../assets/Swipy Screens.png')} alt="swipy presentation" className="project__picture"/>
                </figure>
                <p className="project__info--para">
                Swipy is a cross-platform mobile application which aims to help artists and producers connect together through a discvoery feed system. Users can upload their work to the application's database, and start sharing their work to the world. Swipy allows for an easy connection system through matching invitations which users can send out if interested with the artist/producer's work <br/><br/> Developped using 
                    <div className='project__tech--list'>
                        <div className="project__tech--container">
                            <img src={require('../../../assets/React_L.png')} alt="react" className='tech_logo'/>
                            <strong className='text--purple2'>React Native</strong>,
                        </div>
                        <div className="project__tech--container">
                            <img src={require('../../../assets/Tailwind_L.png')} alt="tailwind" className='tech_logo'/>
                            <strong className='text--purple2'>Tailwind CSS</strong>,
                        </div>
                        <div className="project__tech--container">
                            <img src={require('../../../assets/Redux_L.png')} alt="redux" className='tech_logo'/>
                            <strong className='text--purple2'>Redux</strong>,
                        </div>
                        <div className="project__tech--container">
                            <img src={require('../../../assets/Firebase_L.png')} alt="firebase" className='tech_logo'/>
                            <strong className='text--purple2'>Firebase</strong>
                        </div>
                    </div>
                </p>
              </div>
            </div>
          </div>
        </div>
        </section>
        <section id="features">
            <div className='project_features--container'>
              
                <div className='row'>
                    <h1 className="section__title">Here are some of <span className="text--purple">Swipy's</span> features</h1>
                </div>

                <div className='project_features--one'>
                  <SwipyVideo videoFile={'https://dl.dropboxusercontent.com/s/8l5zsc4qt0y7yae/Swipy_Feed_N.mp4?dl=0'} poster={require('../../../assets/Swipy_Feed-min.png')}/>
                  <div className='project-feature--details'>
                    <h1 className='project_feature--title'>Browse users' best work</h1>
                      <h3 className='project-feature--single'>• All in one feed for songs and beats</h3>
                      <h3 className='project-feature--single'>• <span className='text--purple'>Like </span>and <span className='text--purple'>Connect </span> with other artists</h3>
                      <h3 className='project-feature--single'>• Showcases 3 tracks per user</h3>
                  </div>
                </div>

                <div className='featureDivider'/>

                <div className='project_features--one'>
                  <SwipyVideo videoFile={'https://dl.dropboxusercontent.com/s/o655qvs0ijhx9pp/Swipy_Search_N.mp4?dl=0'} poster={require('../../../assets/Swipy_Search-min.png')}/>
                  <div className='project-feature--details'>
                    <h1 className='project_feature--title'>Search Swipy's users</h1>
                      <h3 className='project-feature--single'>• Remember a potential match?</h3>
                      <h3 className='project-feature--single'>• <span className='text--purple'>Search </span> and <span className='text--purple'>Browse </span> users's profiles to find your next collaboration</h3>
                  </div>
                </div>

                <div className='featureDivider'/>

                <div className='project_features--one'>
                  <SwipyVideo videoFile={'https://dl.dropboxusercontent.com/s/5imk15f8zu721r7/Swipy_Upload_N.mp4?dl=0'} poster={require('../../../assets/Swipy_Upload-min.png')}/>
                  <div className='project-feature--details'>
                    <h1 className='project_feature--title'>Upload your work</h1>
                      <h3 className='project-feature--single'>• Never lose your work and upload to Swipy's cloud</h3>
                      <h3 className='project-feature--single'>• You don't have to choose! You can upload <span className='text--purple'>Songs </span> and <span className='text--purple'>Beats </span> on the same account!</h3>
                      <h3 className='project-feature--single'>• Browse your upload <span className='text--purple'>trending </span> sounds and see your most recent stats</h3>
                  </div>
                </div>

                <div className='featureDivider'/>

                <div className='project_features--one'>
                  <SwipyVideo videoFile={'https://dl.dropboxusercontent.com/s/6cuigkji5cej1vv/Swipy_Messages_N.mp4?dl=0'} poster={require('../../../assets/Swipy_Messages-min.png')}/>
                  <div className='project-feature--details'>
                    <h1 className='project_feature--title'>Message your connections</h1>
                      <h3 className='project-feature--single'>• <span className='text--purple'>Text </span>your matches to arrange your next <span className='text--purple'>collaboration </span></h3>
                      <h3 className='project-feature--single'>• Manage your potential <span className='text--purple'>match invitations </span> in one click</h3>
                      <h3 className='project-feature--single'>• Dont worry, your messages are securely stored within the cloud</h3>
                  </div>
                </div>

                <div className='featureDivider'/>

                <div className='project_features--one'>
                  <SwipyVideo videoFile={'https://dl.dropboxusercontent.com/s/af2i9hmra6wxic9/Swipy_Profile_N.mp4?dl=0'} poster={require('../../../assets/Swipy_Profile-min.png')}/>
                  <div className='project-feature--details'>
                    <h1 className='project_feature--title'>Build your profile</h1>
                      <h3 className='project-feature--single'>• <span className='text--purple'>Browse </span> your profile (or others) and <span className='text--purple'>play </span> any track you see!</h3>
                      <h3 className='project-feature--single'>• <span className='text--purple'>Edit </span>your profile to always reflect your latest status</h3>
                      <h3 className='project-feature--single'>• <span className='text--purple'>Connect </span> with other users by selecting your preffered track</h3>
                  </div>
                </div>

                <div className='featureDivider'/>

                <div className='project_features--one'>
                  <SwipyVideo videoFile={'https://dl.dropboxusercontent.com/s/2n9mwfbi0gbb8tm/Swipy_Login_N.mp4?dl=0'} poster={require('../../../assets/Swipy_Login-min.png')}/>
                  <div className='project-feature--details'>
                    <h1 className='project_feature--title'>Create your account or Login</h1>
                      <h3 className='project-feature--single'>• <span className='text--purple'>Sign out </span> or <span className='text--purple'>Sign in </span> anytime and enjoy Swipy protected authentication</h3>
                      <h3 className='project-feature--single'>• Setup your profile whenever you create a new account</h3>
                  </div>
                </div>
            </div>
        </section>
        <footer className='footer2'>
          <div className="row footer__row">
            <div className="footer__social--list">
              <a href="https://github.com/ZanderBournand" target="_blank" rel="noreferrer" className="
                footer__social--link
                link__hover-effect
                link__hover-effect--white
              ">GitHub</a>
              <a href="https://www.linkedin.com/in/zander-bournand/" target="_blank" rel="noreferrer" className="
                footer__social--link
                link__hover-effect
                link__hover-effect--white
              ">LinkedIn</a>
              <a href="mailto:z.bournand@gmail.com" target="_blank" rel="noreferrer" className="
                footer__social--link
                link__hover-effect
                link__hover-effect--white
              " >Email</a>
              <a href={'/resume'} target="_blank" rel="noreferrer" className="
                footer__social--link
                link__hover-effect
                link__hover-effect--white
              ">Resume</a>
            </div>
            <div className="footer__copyright">Copyright © 2022 Zander Bournand</div>
          </div>
        </footer>
    </body>
    </html>
  )
}

export default Swipy