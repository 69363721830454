import React from 'react';
import resumePDF from '../../assets/resume.pdf';

const iframeStyle = {
  border: 'none',
  width: '100%',
  height: '100vh',
};

function Resume() {
  return (
    <div>
      <iframe
        src={resumePDF}
        title="Resume"
        style={iframeStyle}
      />
    </div>
  );
}

export default Resume;
